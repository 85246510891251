<template>
  <div>
    <dashboard />
  </div>
</template>

<script>
import { getMessaging, getToken } from 'firebase/messaging'
import Dashboard from '@/views/Dashboard/Index.vue'

export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      fcmToken: null, // Tokeni saklamak için bir data alanı ekleyin
    }
  },
  mounted() {
    this.getTokenAndSave()
  },
  methods: {
    async getTokenAndSave() {
      const messaging = getMessaging()
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: 'BO-JQXNGBIIpzsqAJJ87hEBiu1qw6ItuUQpl8yJJ6HWcZDoTknBx4mrVDE6mkyFq91Vkz3HXNgD6TEQ7C92Szls',
        })

        if (currentToken) {
          this.fcmToken = currentToken
          this.$store.dispatch('userTokens/saveToken', {
            fcmToken: currentToken,
          })
        } else {
          console.log("Kullanıcının cihaz token'ı yok.")
        }
      } catch (error) {
        console.error("Cihaz token'ı alınamadı:", error)
      }
    },
  },
}
</script>
